<div class="footer-container">
  <ng-content></ng-content>
  <div class="footer">
    <div class="row">
      <div class="column">
        <ul class="footer-list">
          <li>
            <a 
              class="stat-text-link"
              data-dtm="footer"
              target="_blank"
              href="https://www.gm.com/copyright-trademark?evar25=gm_card_footer"
              >Legal</a
            >
            </li>
          <li *ngIf="isAuthenticated">
            Have Questions?
            <a
              class="stat-text-link"
              data-dtm="footer"
              target="_blank"
              [href]="contactUsLink"
              >Contact Us</a
            >
          </li>
          <li *ngIf="!isAuthenticated">
            <a
              class="stat-text-link mobile-link"
              data-dtm="footer"
              target="_blank"
              href="https://experience.gm.com/rewards/card/my-gm-rewards-card?evar25=gm_card_footer"
              >MyGMRewardsCard.com
              <img
                [src]="'/assets/img/icons/link_out_white.png'"
                alt="Click this link to go to an external site"
                class="link-out"
              />
            </a>
          </li>
          <!-- <li>
            <a
              (click)="openLegalModal()"
              class="modal stat-text-link"
              data-dtm="footer"
              >Legal/Copyright Information</a
            >
          </li> -->
          <li>
            <a
              class="stat-text-link"
              data-dtm="footer"
              target="_blank"
              href="#"
              onclick='window.open("https://info.evidon.com/pub_info/2689?v=1&nt=0&nw=true");return false;'
              rel="noopener noreferrer"
              ><img
                src="../../../../assets/img/icons/link_out_ad.png"
              />AdChoices</a
            >
          </li>
          <li class="facebook-link" *ngIf="!isAuthenticated">
            <a
              class="stat-text-link"
              data-dtm="footer"
              target="_blank"
              href="https://www.facebook.com/gmrewardscards"
              ><img
                src="../../../../assets/img/icons/facebook-mobile.png"
              />Visit Us on Facebook</a
            >
          </li>
        </ul>
      </div>
    </div>

    <div class="row subtext" *ngFor="let disclaimer of disclaimers">
      <div class="column">
        <p [innerHTML]="disclaimer"></p>
      </div>
    </div>
  </div>
</div>

<div class="mobile-container">
  <div class="row">
    <div class="column">
      <mat-expansion-panel class="mobile-footer-section">
        <mat-expansion-panel-header class="stat-expand-icon" data-dtm="footer">
          <mat-panel-title class="section-header">
            Legal/Copyright Information
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ul class="mobile-list">
          <li>
            <a 
              class="stat-text-link"
              data-dtm="footer"
              target="_blank"
              href="https://www.gm.com/copyright-trademark?evar25=gm_card_footer"
              >Legal</a
            >
          </li>
          <li *ngIf="isAuthenticated">
            Have Questions?
            <a
              class="stat-text-link"
              data-dtm="footer"
              target="_blank"
              [href]="contactUsLink"
              >Contact Us</a
            >
          </li>
          <li *ngIf="!isAuthenticated">
            <a
              class="stat-text-link mobile-link"
              data-dtm="footer"
              target="_blank"
              href="https://experience.gm.com/rewards/card/my-gm-rewards-card?evar25=gm_card_privacy_statement_modal"
              >MyGMRewardsCard.com
              <img
                [src]="'/assets/img/icons/link_out_white.png'"
                alt="Click this link to go to an external site"
                class="link-out"
              />
            </a>
          </li>
          <!-- <li
            id="legalModalButton"
            class="modal stat-text-link"
            data-dtm="footer"
            (click)="openLegalModal()"
          >
            Legal/Copyright Information
          </li> -->
          <li
            id="disclModalButton"
            class="modal stat-text-link"
            data-dtm="footer"
            (click)="openDisclaimerModal()"
          >
            Disclaimers
          </li>
        </ul>
      </mat-expansion-panel>

      <div class="mobile-footer-section">
        <ul class="section-list">
          <li>
            <a
              class="
                mobile-img-section
                section-header
                stat-text-link
                facebook-link
              "
              data-dtm="footer"
              href="https://www.facebook.com/gmrewardscards"
              target="_blank"
              ><img
                src="../../../../assets/img/icons/facebook-mobile.png"
              /><span>Visit Us on Facebook</span></a
            >
          </li>
          <li>
            <a
              class="
                mobile-img-section
                section-header
                stat-text-link
                adchoices-link
              "
              data-dtm="footer"
              href="#"
              onclick='window.open("https://info.evidon.com/pub_info/2689?v=1&nt=0&nw=true");return false;'
              ><img src="../../../../assets/img/icons/link_out_ad.png" /><span
                >AdChoices</span
              ></a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
