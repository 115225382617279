<div class="container">
  <div class="row-no-center">
    <div class="ordered">
      <ul class="link-list" class="flexUL">
        <li class="heading" [innerHtml]="cardType.name | uppercase"></li>
        <li>
          <a
            (click)="navigation('summary')"
            class="stat-text-link"
            data-dtm="footer"
            id="summary_link"
            >Summary</a
          >
        </li>
        <li>
          <a
            (click)="navigation('vehicles')"
            class="stat-text-link"
            data-dtm="footer"
            id="vehicles_link"
            >Vehicles</a
          >
        </li>
        <li>
          <a
            (click)="navigation('offers')"
            class="stat-text-link"
            data-dtm="footer"
            id="offers_link"
            >Offers</a
          >
        </li>
        <li>
          <a
            (click)="navigation('redeem')"
            class="stat-text-link"
            data-dtm="footer"
            id="redeem_link"
            >Redeem</a
          >
        </li>
      </ul>
      <ul class="link-list" class="flexUL">
        <li class="heading">CUSTOMER SUPPORT</li>
        <li>
          <a
            (click)="navigation('program-rules')"
            class="stat-text-link"
            data-dtm="footer"
            id="program_rules_link"
            >Program Rules</a
          >
        </li>
        <li>
          <a
            (click)="navigation('faqs')"
            class="stat-text-link"
            data-dtm="footer"
            id="faqs_link"
            >FAQs</a
          >
        </li>
        <li>
          <a
            [href]="contactUsLink"
            class="stat-text-link"
            data-dtm="footer"
            id="contact_us_link"
            target="_blank"
            >Contact Us</a
          >
        </li>
        <li>
          <app-privacy-modal
            id="privacy_statement_link"
            label="GM Privacy Statement"
          ></app-privacy-modal>
        </li>
      </ul>
      <ul class="link-list" class="flexUL">
        <li class="facebook-link">
          <a
            class="stat-text-link"
            data-dtm="footer"
            href="https://www.facebook.com/gmrewardscards"
            target="_blank"
          >
            <img src="../../../../assets/img/icons/facebook-mobile.png" />
            Visit Us on Facebook
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>

<div class="mobile-container">
  <div class="row-no-center">
    <div class="column-no-justify">
      <mat-expansion-panel class="mobile-footer-section">
        <mat-expansion-panel-header>
          <mat-panel-title class="section-header"> GM Card </mat-panel-title>
        </mat-expansion-panel-header>
        <ul class="mobile-list">
          <li>
            <a
              [routerLink]="'../summary'"
              class="stat-text-link"
              data-dtm="footer"
              >Summary</a
            >
          </li>
          <li>
            <a
              [routerLink]="'../vehicles'"
              class="stat-text-link"
              data-dtm="footer"
              >Vehicles</a
            >
          </li>
          <li>
            <a
              [routerLink]="'../offers'"
              class="stat-text-link"
              data-dtm="footer"
              >Offers</a
            >
          </li>
          <li>
            <a
              [routerLink]="'../redeem'"
              class="stat-text-link"
              data-dtm="footer"
              >Redeem</a
            >
          </li>
        </ul>
      </mat-expansion-panel>

      <mat-expansion-panel class="mobile-footer-section">
        <mat-expansion-panel-header>
          <mat-panel-title class="section-header">
            Customer Support
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ul class="mobile-list">
          <li>Program Rules</li>
          <li>
            <a [routerLink]="'/faqs'" class="stat-text-link" data-dtm="footer"
              >FAQs</a
            >
          </li>
          <li>
            <a
              [href]="contactUsLink"
              class="stat-text-link"
              data-dtm="footer"
              id="contact_us_link"
              target="_blank"
              >Contact Us</a
            >
          </li>
        </ul>
      </mat-expansion-panel>

      <mat-expansion-panel class="mobile-footer-section">
        <mat-expansion-panel-header>
          <mat-panel-title class="section-header">
            Legal/Copyright Information
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ul class="mobile-list">
          <li>
            <a 
              class="stat-text-link"
              data-dtm="footer"
              target="_blank"
              href="https://www.gm.com/copyright-trademark?evar25=gm_card_footer"
              >Legal</a
            >
          </li>
          <li>
            Have Questions?
            <a
              class="stat-text-link"
              data-dtm="footer"
              href="https://www.buypowercard.com/?media=C5BA230ABM1510009133XXHHX&WT.mc_id=CRS_C_5BA_AB_009133&WT.mc_id=CRS_C5IM003814&external_id=CRS_C_5BA_AB_009133C5IM099AAX1305003814XXHHX&eVar25=GMCARD_FOOTER_LINK_TO_BPCARD"
              >BuyPowerCard.com</a
            >
          </li>
        </ul>
      </mat-expansion-panel>

      <div class="mobile-footer-section">
        <ul class="section-list">
          <li>
            <a
              class="mobile-img-section section-header stat-text-link"
              data-dtm="footer"
              href="https://www.facebook.com/gmrewardscards"
              target="_blank"
              ><img src="../../../../assets/img/icons/facebook-mobile.png" />
              Visit Us on Facebook</a
            >
          </li>
          <li>
            <a
              class="mobile-img-section section-header stat-text-link"
              data-dtm="footer"
              href="http://info.evidon.com/pub_info/2689?v=1&nt=0&nw=true"
            >
              <img
                src="../../../../assets/img/icons/link_out_ad.png"
              />AdChoices</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
